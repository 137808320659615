import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { SubCategorySlider } from '../../data/slider';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  @Input() allSubcategory
  @Input() categoryId
  imageUrl = environment.imageLinkMedium
  urbanFeature= environment.urbanFeature

  selectedSubcateActive=false
  @Output() newItemEvent = new EventEmitter<string>();

 constructor() { 
 }
 
 public SubCategorySlider: any = SubCategorySlider;


 addNewItem(value: string) {
   this.newItemEvent.emit(value);
   console.log(value)
 }
 ngOnInit(): void {
   // console.log(this.brand)
   console.log(this.categoryId)
   

 }

}
