import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/shared/classes/product';
import { ResponseModel } from 'src/app/shared/classes/response.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment.prod';


declare var google: any;

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @Input() product: Product;
  @Input() addressFromCheckout;
  aboutUs
  @ViewChild("sizeChart", { static: false }) SizeChart: TemplateRef<any>;
  sendOtpEnable = false;
  mobile_number: string;
  otp: string;
  defaultUrl = ""
  public closeResult: string;
  public modalOpen: boolean = false;
  imageUrl = environment.imageLinkMedium
  title

  circleRadius: number = 5000; // km
  // location: Location

  public location: Location = {
    lat: 28.600624167782147,
    lng: 77.38421483457952,
    marker: {
      lat: 28.600624167782147,
      lng: 77.38421483457952,
      draggable: true
    },
    zoom: 10
  };
  cordinet
  lat
  lang
  @ViewChild("AgmMap", { static: false }) map: AgmMap;
  public checkoutForm: FormGroup;
  public address: any[] = [];
  public editing: Boolean = false
  public selectedAddress
  public selectedAddressId
  selectedDeliveryAdderss
  enableAddress = false
  mapStatus=false
  geocoder:any
  currentlocation=false
  addressDataInComponenet$
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal,
    private zone: NgZone, private fb: FormBuilder, public productService: ProductService,
    public mapsApiLoader: MapsAPILoader,private authService:AuthService, private dataService:DataService,
    private wrapper: GoogleMapsAPIWrapper) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit(): void {
    this.addressDataInComponenet$ = this.productService.addressData$
    this.location.marker.draggable = true;
    this.initAddressForm()
    if(this.authService.isAuthenticated()){
    this.getAllAddress()
    this.myLocation()
  }
  }

  myLocation(){
    this.cordinet= []
    this.currentlocation=true
    if(!navigator.geolocation){
     // console.log('location is not supported')
    }
    navigator.geolocation.getCurrentPosition( (position) => {
      //console.log(position)
      this.lat = position.coords.latitude;
      this.lang =position.coords.longitude
      this.location.lat = position.coords.latitude;
      this.location.lng = position.coords.longitude
       this.location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        marker: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          draggable: true
        },
        zoom: 10
      };
     //console.log('latitude = ' + this.lat)
     //console.log('longitude = ' + this.lang)
     this.cordinet.push(this.lat, this.lang)
     //console.log(this.cordinet)
     //console.log(this.location)
     this.findAddressByCoordinates()
    })
  }
  initAddressForm() {
    this.checkoutForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      mobile_number: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
    })
  }
  getAllAddress() {
    this.addressDataInComponenet$.subscribe((res) => {
      this.address = res;
      if(this.address.length>0){
        this.selectedDeliveryAdderss = this.address[0].addressLine1 +", " + this.address[0].addressLine2 ;
        this.selectedAddressId = this.address[0]._id
        this.selectedAddress = this.address[0]
        //console.log(this.address)
        //console.log(this.selectedDeliveryAdderss)
        //console.log(this.selectedAddress)
        this.dataService.changeMessage(this.address[0].pincode)
      }
      // this.setAddresForm()
    })
  }

  submitAddress() {
    //console.log(this.checkoutForm.value)
    //console.log(this.checkoutForm.value);
    if (this.editing) {
      this.updateAddres(this.checkoutForm.value);
    } else {
      this.addAddress(this.checkoutForm.value);
    }
  }
  addAddress(address: any) {
    this.productService.addAddress(address).subscribe((res: ResponseModel) => {
      //console.log(res.data)
      this.enableAddress = false
      let status = true
      this.dataService.addressAddedSuccess(status)
      localStorage.setItem('pincode', address.pincode)
      this.dataService.changeMessage(address.pincode)
      this.getAllAddress()
      if (this.modalOpen) {
        this.modalService.dismissAll();
      }
      this.checkoutForm.reset()
      window.location.reload();
    })
  }
  updateAddres(address: any) {
    this.productService.updateAddress(this.selectedAddressId, address).subscribe((res: ResponseModel) => {
      //console.log(res.data)
      this.editing = false
      this.enableAddress = false
      this.getAllAddress()
      if (this.modalOpen) {
        this.modalService.dismissAll();
      }
      this.checkoutForm.reset()
    })
  }

  setAddresForm(){
    //console.log(this.checkoutForm.value);
    if((this.selectedAddress)){
      this.checkoutForm.controls['name'].setValue(this.selectedAddress.name);
      this.checkoutForm.controls['mobile_number'].setValue(this.selectedAddress.mobile_number);
      this.checkoutForm.controls['addressLine1'].setValue(this.selectedAddress.addressLine1);
      this.checkoutForm.controls['addressLine2'].setValue(this.selectedAddress.addressLine2);
      this.checkoutForm.controls['city'].setValue(this.selectedAddress.city);
      this.checkoutForm.controls['state'].setValue(this.selectedAddress.state);
      this.checkoutForm.controls['pincode'].setValue(this.selectedAddress.pincode);
      //console.log(this.checkoutForm.value);
    }
  }
  setAddressByLocation(){
    if((this.currentlocation===true)){
      this.checkoutForm.controls['addressLine1'].setValue(this.location.address_level_1);
      this.checkoutForm.controls['addressLine2'].setValue(this.location.address_level_2);
      this.checkoutForm.controls['city'].setValue(this.location.address_level_2);
      this.checkoutForm.controls['state'].setValue(this.location.address_state);
      this.checkoutForm.controls['pincode'].setValue(this.location.address_zip);
    }
  }
  findLocation(address) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      //console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types
         // console.log(types);
          if (types.indexOf('locality') != -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name
          }
          if (types.indexOf('country') != -1) {
            this.location.address_country = results[0].address_components[i].long_name
          }
          if (types.indexOf('postal_code') != -1) {
            this.location.address_zip = results[0].address_components[i].long_name
          }
          if (types.indexOf('administrative_area_level_1') != -1) {
            this.location.address_state = results[0].address_components[i].long_name
          }
        }
        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.marker.lat = results[0].geometry.location.lat();
          this.location.marker.lng = results[0].geometry.location.lng();
          this.location.marker.draggable = true;
          this.location.viewport = results[0].geometry.viewport;
        }

        this.map.triggerResize()
      } else {
        alert("Sorry, this search produced no results.");
      }
    })
  }

  findAddressByCoordinates() {
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  if(this.geocoder){
    this.geocoder.geocode({
      'location': {
        lat: this.location.marker.lat,
        lng: this.location.marker.lng
      }
    }, (results, status) => {
      this.decomposeAddressComponents(results);
    })
  }
    

  }

  decomposeAddressComponents(addressArray) {
    if (addressArray.length && addressArray.length == 0) return false;
    let address = addressArray[0].address_components;
    //console.log(address);
    for (let element of address) {
      //console.log(element);
      if (element.length == 0 && !element['types']) continue
      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('route') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('locality') > -1) {
        this.location.address_level_2 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_zip = element['long_name'];
        continue;
      }
    }
   // console.log(this.location);
    this.setAddressByLocation()

  }

  updateOnMap() {
   // console.log('updating')
    let full_address: string = this.location.address_level_1 || ""
    if (this.location.address_level_2) full_address = full_address + " " + this.location.address_level_2
    if (this.location.address_state) full_address = full_address + " " + this.location.address_state
    if (this.location.address_country) full_address = full_address + " " + this.location.address_country
    this.findLocation(full_address);
    //console.log(full_address);
  }

  circleRadiusInKm() {
    return this.circleRadius / 1000;
  }

  milesToRadius(value) {
    this.circleRadius = value / 0.00062137;
  }

  circleRadiusInMiles() {
    return this.circleRadius * 0.00062137;
  }

  markerDragEnd(m: any, $event: any) {
    this.location.marker.lat = m.coords.lat;
    this.location.marker.lng = m.coords.lng;
    this.findAddressByCoordinates();
  }


  openModal() {
    // this.selectedAddress={}
    // this.checkoutForm.reset()
    if((!this.selectedAddress)){
      this.myLocation()
    }else if((this.selectedAddress)){
    this.setAddresForm()
    }
    //console.log("location")
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SizeChart, {
        size: 'md',
        ariaLabelledBy: 'size-modal',
        centered: true,
        windowClass: 'SizeChart'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  // openModalCheckout() {
  //   if(this.addressFromCheckout){
  //   this.checkoutForm.reset()
  //   this.setAddresFormForCheckout()
  //   }else{
  //     this.myLocation()

  //   }
  //   this.modalOpen = true;
  //   if (isPlatformBrowser(this.platformId)) { // For SSR 
  //     this.modalService.open(this.SizeChart, {
  //       size: 'md',
  //       ariaLabelledBy: 'size-modal',
  //       centered: true,
  //       windowClass: 'SizeChart'
  //     }).result.then((result) => {
  //       `Result ${result}`
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     });
  //   }
  // }

  // setAddresFormForCheckout(){
  //   console.log(this.checkoutForm.value);
  //   if(this.addressFromCheckout){
  //     this.checkoutForm.controls['name'].setValue(this.addressFromCheckout.name);
  //     this.checkoutForm.controls['mobile_number'].setValue(this.addressFromCheckout.mobile_number);
  //     this.checkoutForm.controls['addressLine1'].setValue(this.addressFromCheckout.addressLine1);
  //     this.checkoutForm.controls['addressLine2'].setValue(this.addressFromCheckout.addressLine2);
  //     this.checkoutForm.controls['city'].setValue(this.addressFromCheckout.city);
  //     this.checkoutForm.controls['state'].setValue(this.addressFromCheckout.state);
  //     this.checkoutForm.controls['pincode'].setValue(this.addressFromCheckout.pincode);
  //     console.log(this.checkoutForm.value);
  //   }

  // }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  toggleMapStatus(){
    this.mapStatus = !this.mapStatus
  }
}
