import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { MyProduct } from '../../classes/myproduct';
import { ResponseModel } from '../../classes/response.model';
import { environment } from 'src/environments/environment.prod';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  imageUrl = environment.imageLinkMedium
  hahaMartFeature= environment.hahaMartFeature
  //public products: MyProduct[] = [];
  public search: boolean = false;
  cartItem
  products :any[] = []
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  token
  public grid: string = 'col-xl-3 col-md-3 col-xs-6';
  public layoutView: string = 'grid-view';
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  productName
  allProducts:any[]=[];
  categoryBanner
  finished
  allItems
  allVarient
  items
  isFavirote=false
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,private dataService:DataService,
    public productService: ProductService, public authService:AuthService) {
    // this.productService.cartItems.subscribe(response => this.products = response);
    this.dataService.currentCart.subscribe( (data:any) => {
      this.cartItem = data
      this.products = data.products
      // console.log(this.cartItem)
    })
    this.token = localStorage.getItem('token')
  if(this.token){
    this.getCartItem()
  }
    
  }

  ngOnInit(): void {
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }
  toggleSumMenu(){
    this.isFavirote = !this.isFavirote
  }
  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  getCartItem(){
    this.productService.getCartItem().subscribe((res:ResponseModel)=>{
      // console.log(res.data)
      // this.toaster.success('Cart Item Fetched  Successfully', 'Success');
      this.cartItem = res.data
      this.products = res.data.products
    })
  }
  searchProduct(){
    this.productService.searchProduct('?query='+this.productName).subscribe((res:ResponseModel)=>{
      console.log(res.data)
      let result = res.data.map(arrayItem=>{
        arrayItem.varients = arrayItem.varients.map(varient=>{
        varient.product = arrayItem.product;
        return varient;
      })
      return arrayItem;
    })
    this.allProducts = result; // get current page of items
    })
  }
}
