import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";
import { ResponseModel } from "../../classes/response.model";
import { LoginModalComponent } from "../../components/modal/login-modal/login-modal.component";
import { AuthService } from "../../services/auth.service";
import { DataService } from "../../services/data.service";
import { MainService } from "../../services/main.service";
import { Menu, NavService } from "../../services/nav.service";
import { ProductService } from "../../services/product.service";
declare const annyang: any;

@Component({
  selector: "app-bigbasket-header",
  templateUrl: "./bigbasket-header.component.html",
  styleUrls: ["./bigbasket-header.component.scss"],
})
export class BigbasketHeaderComponent implements OnInit {
  @Input() themeLogo: string;
  products: any[] = [];
  public menuItems: Menu[];
  public allCategory;
  isFavirote = false;
  isFavirote1 = false;
  @ViewChild("login") login: LoginModalComponent;
  imageUrl = environment.imageLinkMedium;
  sagarFeature = environment.sagarFeature;
  hahaMartFeature = environment.hahaMartFeature;
  summitFeature = environment.summitFeature;
  pincode;
  mailto: any;
  callNumber;
  allSubcategory;
  featureCategory;
  cartItem;
  token;
  aboutUs;
  productName: string;
  searchProduct: any[] = [];
  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  placeholder = "What are you looking for?";
  allCategoryDataInComponenet$;
  allCategoryDataByLocationInComponenet$;
  aboutUsInComponenet$;
  aboutUs1: any;
  mobile: string;
  @ViewChild("toggleButton") toggleButton: ElementRef;
  constructor(
    private router: Router,
    public navServices: NavService,
    public authService: AuthService,
    private dataService: DataService,
    private productService: ProductService,
    private mainService: MainService,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
    // this.renderer.listen("window", "click", (e: Event) => {
    //   /**
    //    * Only run when toggleButton is not clicked
    //    * If we don't check this, all clicks (even on the toggle button) gets into this
    //    * section which in the result we might never see the menu open!
    //    * And the menu itself is checked here, and it's where we check just outside of
    //    * the menu and button the condition abbove must close the menu
    //    */
    //   if (e.target != this.toggleButton.nativeElement) {
    //     this.isFavirote1 = false;
    //   }
    // });
    this.dataService.currentCart.subscribe((data: any) => {
      this.cartItem = data;
      this.products = data.products;
      // console.log(this.cartItem)
    });
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.getCartItem();
    }
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      // console.log(menuItems)
    });
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.allCategoryDataInComponenet$ = this.mainService.allCategoryData$;
    this.allCategoryDataByLocationInComponenet$ =
      this.mainService.allCategoryDataByLocation$;
    this.aboutUsInComponenet$ = this.mainService.aboutUsData$;

    this.getAbouUs();
    this.pincode = localStorage.getItem("pincode");
    if (!this.authService.isAuthenticated) {
      this.getAllCategory();
    }
    if (this.authService.isAuthenticated) {
      this.getAllCategoryByLOcation();
    }
    this.dataService.currentMessage.subscribe((res) => {
      console.log(res);
      this.getAllCategoryByLOcation();
    });
    this.getFeatureCategory();
  }
  getFeatureCategory() {
    this.mainService.getFeatureCategory().subscribe((res: ResponseModel) => {
      this.featureCategory = res.data;
    });
  }
  changeMenu() {
    this.isFavirote1 = true;
  }

  // ngDoCheck(){
  //   this.pincode = localStorage.getItem('pincode')
  //   console.log('pincode',this.pincode)
  //   this.getAllCategoryByLOcation()
  // }

  getCartItem() {
    this.productService.getCartItem().subscribe((res: ResponseModel) => {
      // console.log(res.data)
      // this.toaster.success('Cart Item Fetched  Successfully', 'Success');
      this.cartItem = res.data;
      this.products = res.data.products;
    });
  }
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  getAbouUs() {
    this.aboutUsInComponenet$.subscribe((res: ResponseModel) => {
      this.aboutUs = res.data;
      this.aboutUs1 = res.data.mobile;
      console.log("this is about us", this.aboutUs1);

      this.mobile =
        this.aboutUs1.substring(0, 5) + " " + this.aboutUs1.substring(5);
      localStorage.setItem("whatsapp", res.data.whatsapp);
      localStorage.setItem("mobile", res.data.mobile);
      this.themeLogo = this.imageUrl + "/" + this.aboutUs.logo;
      this.callNumber = "tel:" + this.aboutUs.mobile;

      this.mailto = "mailto:" + this.aboutUs.email;
    });
  }
  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }
  getAllCategory() {
    this.allCategoryDataInComponenet$.subscribe((res: ResponseModel) => {
      console.log(res.data);
      this.allCategory = res.data;
    });
  }
  getAllCategoryByLOcation() {
    this.allCategoryDataByLocationInComponenet$.subscribe((res) => {
      console.log(res);
      this.allCategory = res;
    });
  }

  getAllSubCategory(category) {
    this.productService
      .getAllCategorysub(category)
      .subscribe((res: ResponseModel) => {
        this.allSubcategory = res.data;
        console.log(this.allSubcategory);
        this.isFavirote1 = !this.isFavirote1;
      });
  }
  toggleSumMenu() {
    this.isFavirote = !this.isFavirote;
  }
  openlLogin() {
    this.login.openModal();
  }

  productSearchParams() {
    if (this.productName.length) {
      // console.log(this.productName)
      this.productService
        .findProduct(this.productName)
        .subscribe((res: ResponseModel) => {
          this.searchProduct = res.data;
          console.log(res.data);
        });
    }
  }
  gotoProductpage(id) {
    this.router.navigate(["/product/details/", id]);
    this.searchProduct.length = 0;
  }
  initializeVoiceRecognitionCallback(): void {
    annyang.addCallback("error", (err) => {
      if (err.error === "network") {
        this.voiceText = "Internet is require";
        annyang.abort();
        this.ngZone.run(() => (this.voiceActiveSectionSuccess = true));
      } else if (this.voiceText === undefined) {
        this.ngZone.run(() => (this.voiceActiveSectionError = true));
        annyang.abort();
      }
    });

    annyang.addCallback("soundstart", (res) => {
      this.ngZone.run(() => (this.voiceActiveSectionListening = true));
    });

    annyang.addCallback("end", () => {
      if (this.voiceText === undefined) {
        this.ngZone.run(() => (this.voiceActiveSectionError = true));
        annyang.abort();
      }
    });

    annyang.addCallback("result", (userSaid) => {
      this.ngZone.run(() => (this.voiceActiveSectionError = false));

      let queryText: any = userSaid[0];

      annyang.abort();

      this.voiceText = queryText;
      console.log(this.voiceText);
      this.productName = this.voiceText;
      this.productSearchParams();
      this.ngZone.run(() => (this.voiceActiveSectionListening = false));
      this.ngZone.run(() => (this.voiceActiveSectionSuccess = true));
    });
  }

  startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceText = undefined;
    // this.productName = ''
    this.placeholder = "Listening.....";
    if (annyang) {
      let commands = {
        "demo-annyang": () => {},
      };

      annyang.addCommands(commands);

      this.initializeVoiceRecognitionCallback();

      annyang.start({ autoRestart: false });
    }
  }

  closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceActiveSectionListening = false;
    this.voiceText = undefined;

    if (annyang) {
      annyang.abort();
    }
  }
}
