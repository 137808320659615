import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ResponseModel } from '../../classes/response.model';
import { MainService } from '../../services/main.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo // string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  @Input() aboutUs;
  googleLogo = "assets/images/custom/google-icon.png"
  iosLogo = "assets/images/custom/apple-icon.png"
  public today: number = Date.now();
  imageUrl = environment.imageLinkMedium
  toganiFeature = environment.toganiFeature
  kudlaFeature = environment.kudlaFeature
  buddy_Firms = environment.buddy_Firms
  defaultFeature= environment.defaultFeature
  hahaMartFeature= environment.hahaMartFeature
  grannyFeature= environment.grannyFeature
  prakshFeature= environment.prakshFeature
  sellerPolicy= environment.sellerPolicy
  readMoreString = "<a href='javascript:void(0)' routerLink='/pages/aboutus'>...Read more</a>"
  allCategory:any[]=[]
  brand
  whatsapp
  mailto:any
  callNumber
  aboutUsInComponenet$
  mobile: string;
  aboutUs1: any;
  Email: any;
  constructor( private mainService:MainService, private navServices: NavService) {
   }

  ngOnInit(): void {
    this.aboutUsInComponenet$ = this.mainService.aboutUsData$
    this.getAbouUs()
    this.getAllBrand()
    console.log("what is about", this.aboutUs);
    
    //this.getAllCategory()
  }

  getAbouUs() {
    this.aboutUsInComponenet$.subscribe((res:ResponseModel) => {
      this.aboutUs = res.data;
      this.aboutUs1 = res.data.mobile
      this.mobile = this.aboutUs1.substring(0, 5) + " " + this.aboutUs1.substring(5);
      console.log("++++___---")
      console.log("about us data is",this.aboutUs);
      console.log("++++___++---")
      localStorage.setItem('whatsapp', res.data.whatsapp)
      localStorage.setItem('mobile', res.data.mobile)
      this.themeLogo = this.aboutUs.logo   
      this.callNumber = "tel:"+this.aboutUs.mobile

      this.mailto = "mailto:"+this.aboutUs.email
    });
  }
  getAllBrand(){
    this.mainService.getAllBrand().subscribe((res:ResponseModel)=>{
      this.brand = res.data
      // console.log("brand", this.brand)
      
    })
  }
  // getAllCategory(){
  //   this.navServices.getAllCategory().subscribe((res:ResponseModel)=>{
  //     // console.log(res.data)
  //     this.allCategory = res.data
  //   })
  // }
}
