import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ResponseModel } from '../../classes/response.model';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { LoginModalComponent } from '../modal/login-modal/login-modal.component';
import { environment } from 'src/environments/environment.prod';
import { ProductService } from '../../services/product.service';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements  OnInit {

  public menuItems: Menu[];
  public allCategory   
  isFavirote = false;
  @ViewChild("login") login: LoginModalComponent;
  imageUrl = environment.imageLinkMedium
  sagarFeature = environment.sagarFeature
  hahaMartFeature= environment.hahaMartFeature
  sellerPolicy= environment.sellerPolicy

  pincode
  allSubcategory
  allCategoryDataInComponenet$
  allCategoryDataByLocationInComponenet$
  constructor(private router: Router, public navServices: NavService,public authService:AuthService,
              private dataService:DataService, private productService:ProductService
              , public mainServices: MainService) {
    this.navServices.items.subscribe(menuItems =>
      {
        this.menuItems = menuItems
        // console.log(menuItems)
      });
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.allCategoryDataInComponenet$=this.mainServices.allCategoryData$   
    this.allCategoryDataByLocationInComponenet$ = this.mainServices.allCategoryDataByLocation$

    this.pincode = localStorage.getItem('pincode')
    if(!this.authService.isAuthenticated){
      this.getAllCategory()
    }
    if(this.authService.isAuthenticated){
      this.getAllCategoryByLOcation()
    }
    this.dataService.currentMessage.subscribe((res)=>{
      console.log(res)
      this.getAllCategoryByLOcation()
    })
  }
  
  // ngDoCheck(){
  //   this.pincode = localStorage.getItem('pincode')
  //   console.log('pincode',this.pincode)
  //   this.getAllCategoryByLOcation()
  // }


  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }
  getAllCategory(){
    this.allCategoryDataInComponenet$.subscribe((res:ResponseModel)=>{
      console.log(res.data)
      this.allCategory = res.data
    })
  }
  getAllCategoryByLOcation(){
    this.allCategoryDataByLocationInComponenet$.subscribe((res)=>{
      console.log(res)
      this.allCategory = res
    })
  }


  getAllSubCategory(category) {
    this.productService.getAllCategorysub(category).subscribe((res: ResponseModel) => {
      this.allSubcategory = res.data;
      console.log(this.allSubcategory)

    });
  }
  toggleSumMenu(){
    this.isFavirote = !this.isFavirote
  }
  openlLogin(){
    this.login.openModal()
  }
}
